import React, {useState, useEffect} from 'react';
import { useUser } from "../contexts/user";
import { Button, Row, Form, Modal, Container} from 'react-bootstrap';
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MsgTypes } from "../utils/Websockets";
import '../css/poi.css';

let timeoutId;

const ModalEditPoi = ({
    show,
    setShow,
    poi,
}) => {
    const { ws, username, newMsg} = useUser();
    const [saving, setSaving] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    const handleClose = () => {
        setShow(false);
    };

    useEffect(()=>{
        if(!newMsg)return;
        if (newMsg.id.split("-")[0] === MsgTypes.UpdatePsra){ // UpdatePsra-<updateRowId> for selecting new updates
            setSaving(false);
            setUpdateSuccess(newMsg.data.changedRows === 1 ?
                (<span style={{color:"#ffc107"}}> 
                    <CiCircleCheck style={{fontSize:"1.5rem"}}/> Saved
                </span>)
                :<span style={{color:"red"}}> 
                    <CiCircleRemove style={{fontSize:"1.5rem"}}/> Error updating, please try again.
                </span>
            );
            clearTimeout(timeoutId);
            timeoutId = setTimeout(()=>setUpdateSuccess(<></>),2000);
        }
    },[newMsg, setShow]);

    /* Updates PSRA and Timeline */
    const handleSubmit  = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const id = data.get("id");
        const timestamp = data.get("timestamp");
        const source = data.get("source");
        const value = data.get("value");
        setSaving(true);
        setUpdateSuccess(<></>);
        ws.updatePsra(id,timestamp,source,value,username);
    };
return(
<ThemeProvider theme={darkTheme}>
                <Modal 
                className="poi-modal" 
                show={show} 
                setShow={setShow} 
                onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Edit POI
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{padding:0, overflow:"hidden"}}>
                        <Container>
                            <Row className="pt-4">
                            <Form id={poi.Id} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="poi">
                                    <Form.Control
                                        name="id"
                                        type="hidden"
                                        defaultValue={poi.Id}
                                        required
                                    />
                                    <TextField
                                        label="Event Timestamp" 
                                        type="text"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Timestamp ?? ""}
                                        name="timestamp" 
                                        placeholder="Timestamp" 
                                        required
                                    />
                                <Row><br></br></Row>

                                    <TextField
                                        label="Source (URL preferred)"
                                        color="warning" 
                                        style={{width:"100%"}}
                                        defaultValue={poi.Source ?? ""} 
                                        name="source" 
                                        placeholder="Source (URL preferred)" 
                                        required
                                    />
                                <Row><br></br></Row>
                                    <TextField
                                        label="POI Details"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Value ?? ""} 
                                        name="value" 
                                        placeholder="Value" 
                                        required
                                    />
                                <Row><br></br></Row>
                                    <div className="mb-2">
                                            Verified by: <b>{username}</b> 
                                    </div>
                                    <br/>
                                <Button variant="warning" 
                                        type="submit"
                                        disabled={saving} 
                                        >Update</Button>
                                {updateSuccess}
                                </Form.Group>
                            </Form>
                            </Row>
                        </Container>

                    </Modal.Body>
                    
            </Modal>
            </ThemeProvider>
)
}
export default ModalEditPoi;