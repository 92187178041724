import React, {useState, useEffect} from 'react';
import { Button, Row, Card } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { timeDifference, isLoggedIn } from '../utils/Utils';
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { useUser } from "../contexts/user";
import ModalAddPoi from './ModalAddPoi';
import ModalEditPoi from './ModalEditPoi';
import '../css/poi.css';


const Poi = ({ sat, pois }) => {
    const {username} = useUser();
    const [showEditPoi, setShowEditPoi] = useState(false);
    const [sortedPois, setSortedPois] = useState([]);
    const [showAddPoi, setShowAddPoi] = useState(false);
    const [poi, setPoi] = useState({})

    useEffect(()=>{
        if(Array.isArray(pois)){
            const sorted = pois.sort((a,b)=>new Date(b.Timestamp) - new Date(a.Timestamp));
            setSortedPois(sorted);
        }
    }, [pois]);

    const [showTitle, setShowTitle] = useState("**No events to show")

    const editData = (poi) => {
        setPoi(poi);
        setShowEditPoi(!showEditPoi);
    }

    useEffect(()=>{
        if(sortedPois.length>0){
                setShowTitle(`${sortedPois.length} event(s)`);
        }
    }, [sortedPois])

  return (
    isLoggedIn(username) ?
        <>
            <ModalAddPoi
                show={showAddPoi}
                setShow={setShowAddPoi}
                sat={sat}
            />
            <Card className='mt-3'>
            <Card.Title style={{textAlign: "center", margin:"1rem", color:"#ffc107"}}>
                <p>Point of Interest Timeline</p>
                <h5 style={{color:"white"}}>{showTitle}</h5>
            </Card.Title>
            <Card.Body>
            {sortedPois.length > 0
            && (<>
            <div style={{textAlign: "center", margin:"0",color: "#ffc107"}}><h5>Newest</h5></div>
            {sortedPois.map((poi) => (
                <Timeline 
                    key={Math.random().toString()}
                    className="mb-0">
                        <TimelineItem
                            style={{paddingTop:'0px'}}>
                            <TimelineOppositeContent >
                                {timeDifference(poi.Timestamp)}
                                <br/>
                                ({poi.Timestamp}Z)
                            </TimelineOppositeContent>
                            <TimelineSeparator>

                                <TimelineConnector/>

                                <div className="edit-box"  onClick={() => editData(poi)}><FiEdit transform="translate(0 -6)"cursor="pointer"/></div>
                                
                                <TimelineConnector sx={{height:'50%'}}/>
                                
                            </TimelineSeparator>
                            <TimelineContent style={{fontWeight:"bold"}}>{poi.Value}</TimelineContent>
                        </TimelineItem>
                </Timeline>
            ))}
            <div style={{textAlign: "center", margin:"0",color: "#ffc107"}}><h5>Oldest</h5></div>
            </>
            )}
            <ModalEditPoi
                show={showEditPoi}
                setShow={setShowEditPoi}
                poi={poi}
            />
            <br></br>
            
            <Row className="text-center">
                <br></br>
                <Button
                    style={{
                        maxWidth:"150px",
                        marginLeft:"auto",
                        marginRight: "auto"
                    }}
                    variant="warning"
                    onClick={()=>setShowAddPoi(!showAddPoi)}
                >
                    <LiaMapMarkerAltSolid /> Add POI
                </Button>

            </Row> 
            </Card.Body>
        </Card>
        </>
    : 
        <div
            className="text-center pt-4"
            style={{color:"red"}}
        >
            Must be logged in to view the Point of Interest timeline.
        </div>
  );
};

export default Poi;
