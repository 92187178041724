import React, {useState, useEffect, useCallback} from 'react';
import {Row, Col, Button, Container, Form, InputGroup, Image} from "react-bootstrap";
import { Grid } from '@mui/material';
import ModalEditData from "../components/ModalEditData";
import ModalAddSatellite from "../components/ModalAddSatellite";
import SatelliteCard from '../components/SatelliteCard';
import SearchModal from '../components/SearchModal';
import { LuInfo, LuFilter, LuList, LuX, LuArrowUpRight, LuArrowDownRight, LuPlus } from "react-icons/lu";
import ProbeLogoPNG from "../assets/images/ProbeLogoPNG.png";
import { IoIosSearch } from "react-icons/io";
import { useUser } from "../contexts/user";
import Loading from "../components/Loading";
import { isLoggedIn } from "../utils/Utils";
import { debouncedSearch, filterSatellites } from '../utils/SearchUtils';

const Home = () => {
    const { username, satellites } = useUser();
    const [loggedIn, setLoggedIn] = useState(false);
    const [filters, setFilters] = useState([]);
    const [filteredSats, setFilteredSats] = useState([]);
    const [showSatModal, setShowSatModal] = useState(false);
    const [hrrChecked, setHrrChecked] = useState(false);
    const [selectedSat, setSelectedSat] = useState(null);
    const [showAddSatModal, setShowAddSatModal] = useState(false);
    const [showQueryModal, setShowQueryModal] = useState(false);
    const handleOpenQueryModal = () => setShowQueryModal(true);
    const handleCloseQueryModal = () => setShowQueryModal(false);
    const [parameter, setParameter] = useState('--');
    const [operator, setOperator] = useState('=');
    let [value, setValue] = useState('');
    const [visibleCount, setVisibleCount] = useState(9); // Number of satellites initially visible
    const [isDescending, setIsDescending] = useState(false);

    useEffect(() => {
        setLoggedIn(isLoggedIn(username));
    }, [username, satellites]);

    useEffect(()=>{
        setSelectedSat(satellites[selectedSat?.SatNo]);
    }, [satellites, selectedSat]);
    
    useEffect(() => {
        if (hrrChecked) {
            const matches = Object.values(satellites).filter(s => s.HrrRank !== null);
            matches.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(matches);
        } else {
            const allSats = Object.values(satellites);
            allSats.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(allSats);
        }
    }, [satellites, hrrChecked]);

    const handleSearchChange = useCallback((event) => {
        const searchValue = event.target.value;
        if (searchValue === '') {
            const allSats = Object.values(satellites);
            allSats.sort((a, b) => a.SatNo - b.SatNo);
            setFilteredSats(allSats);
        } else {
            debouncedSearch(searchValue, satellites, setFilteredSats, hrrChecked);
        }
    }, [satellites, hrrChecked, setFilteredSats]);

    const addFilter = () => {
        //Handle "Launch Date" Parameter
        if (parameter === "LaunchDate"){
            value = value + " 00:00:00"
        }
        const newFilter = { 
            parameter, 
            operator, 
            value: ['<', '>', '<=', '>='].includes(operator) ? parseFloat(value) : value 
        };
        const updatedFilters = [...filters, newFilter];
        setFilters(updatedFilters);
        setParameter('--');
        setOperator('=');
        setValue('');

        // Apply the filter immediately
        const filteredResults = filterSatellites(satellites, updatedFilters, hrrChecked);
        filteredResults.sort((a, b) => a.SatNo - b.SatNo);
        setFilteredSats(filteredResults);
        setVisibleCount(9); // Reset visible count when filters are applied
    };

    const removeFilter = (index) => {
        const updatedFilters = filters.filter((_, i) => i !== index);
        setFilters(updatedFilters);

        // Apply the updated filters immediately
        const filteredResults = filterSatellites(satellites, updatedFilters, hrrChecked);
        filteredResults.sort((a, b) => a.SatNo - b.SatNo);
        setFilteredSats(filteredResults);
        setVisibleCount(9); // Reset visible count when filters are removed
    };

    const removeAllFilters = () => {
        setFilters([]);

        // Apply the updated filters immediately
        const filteredResults = filterSatellites(satellites, filters, hrrChecked);
        filteredResults.sort((a, b) => a.SatNo - b.SatNo);
        setFilteredSats(filteredResults);
        setVisibleCount(9); // Reset visible count when filters are removed
    };

    // Lazy Load more satellites when scrolling near bottom
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50) {
                if (visibleCount < filteredSats.length){
                    setVisibleCount((prevCount) => prevCount + 9); // Load 9 more satellites
                }
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [visibleCount,filteredSats]);

    const sortedSatsOrder = () => { //default: ascending
        const orderedSats = filteredSats.map((sat) => (sat));
        if (isDescending){
            orderedSats.reverse();
        }
        return orderedSats;
    }

    const isAscendDescend = () =>{
        return (isDescending
            ? <><LuArrowDownRight className="icon-lg" /> Descending</>
            : <><LuArrowUpRight className="icon-lg" /> Ascending</>);
    }

    return (
        <>
            <ModalEditData 
                show={showSatModal}
                setShow={setShowSatModal}
                showFooter={true}
                selectedSat={selectedSat}
            />
            <Container style={{ paddingTop: "25px" }}>

            <div className="hero-section" style={{ backgroundImage: 'url("/path-to-your-image.jpg")', backgroundSize: 'cover', color: '#fff', textAlign: 'center', padding: '30px 0' }}>
                <Container>
                    <Row>
                        <Col>
                            <Image width="400px" src={ProbeLogoPNG} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{paddingTop:"1rem"}}>
                            <p>Open Source repository for global satellite data.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

                <Row>
                    <Col sm="10">
                        <h4>Satellites ({filteredSats.length})</h4>
                    </Col>
                </Row>
                <br/>

                {/* SEARCH */}
                <Row className="mb-1">
                    <Col>
                    <Form.Group controlId="search" className="border border-top-0 rounded-3">
                        <InputGroup>
                            <Button
                                tabIndex="99"
                                variant="warning"
                            >
                                <IoIosSearch style={{fontSize:"1.25rem", margin:".2rem"}}/>
                            </Button>
                            <Button
                                variant="outline-secondary"
                                title="Search Filters"
                                onClick={handleOpenQueryModal}
                            >
                                <LuFilter style={{fontSize:"1rem"}}/><LuList style={{fontSize:"1.25rem"}}/>
                            </Button>
                            
                            <Form.Control
                                required
                                type="text"
                                className="form-control-lg"
                                name="search"
                                placeholder="Search (NoradId, Name, Data etc)..."
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                        
                        
                        {/* Applied Filters */}
                        {filters.length > 0 && (
                            <span>
                                <p style={{margin:".5rem"}} >Applied Filters:</p>
                                <ul style={{ paddingLeft: '0', listStyleType: 'none', display: 'flex', flexWrap: 'wrap', gap: '5px', margin:".5rem" }}> {/* Reduced gap */}
                                    {filters.map((filter, index) => (
                                        <li key={index} style={{ display: 'flex', alignItems: 'center'}}> {/* Reduced margin-bottom */}
                                            <span className="bg-secondary"
                                                style={{ 
                                                marginRight: '.25rem', 
                                                backgroundColor: '#4D5656', 
                                                padding: '5px 10px', 
                                                borderRadius: '5px'
                                            }}>
                                                {filter.parameter} {filter.operator} "{filter.value}" 
                                                <button className="btn btn-secondary" 
                                                onClick={() => removeFilter(index)}
                                                style={{padding:"0px",marginLeft:"4px",marginBottom:"4px"}}>
                                                    <LuX fontSize={"1.25rem"}/>
                                                </button>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </span>
                        )}  
                        
                    </Form.Group>
                    </Col>
                    <p style={{ paddingTop:"1rem"}}>
                        <LuInfo/> This is publicly available information and was not provided through Intel channels.
                    </p>
                </Row>
                
                <Row>
                <Col style={{ display: 'flex', alignItems: 'normal', gap:"10px"}}>
                {isLoggedIn(username) && (
                    <>
                        <Button
                            type="checkbox"
                            className="mb-4"
                            variant={hrrChecked ? "success" : "light"}
                            onClick={() => {setHrrChecked(!hrrChecked); removeAllFilters();}}
                        >
                            HRR Only
                        </Button>
                    </>
                )}
                <Button
                    className="mb-4"
                    variant="light"
                    onClick={() => setIsDescending(!isDescending)}
                >
                    {isAscendDescend()}
                </Button>

                {isLoggedIn(username) && (
                    <Button
                    className="mb-4"
                    variant="warning"
                    onClick={() => setShowAddSatModal(true)}
                    style={{marginLeft:"auto"}}
                    >
                        <div 
                        style={{verticalAlign:"center"}}>
                            <LuPlus/> Add Satellite
                        </div>
                    </Button>
                )}
                </Col>
                </Row>

                <SearchModal 
                    show={showQueryModal}
                    handleClose={handleCloseQueryModal}
                    parameter={parameter}
                    setParameter={setParameter}
                    operator={operator}
                    setOperator={setOperator}
                    value={value}
                    setValue={setValue}
                    addFilter={addFilter}
                    filters={filters}
                    removeFilter={removeFilter}
                />
                
                {Object.keys(satellites).length === 0 && (<Loading/>)}
                <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                    {sortedSatsOrder().slice(0, visibleCount).map((sat) => (
                        <Grid item key={sat?.Id ?? Math.random().toString()} xs={12} sm={12} md={6} lg={4}>
                            <SatelliteCard
                                key={sat?.Id ?? Math.random().toString()}
                                setShowSatModal={setShowSatModal} 
                                sat={sat}
                                loggedIn={loggedIn}
                                setSelectedSat={setSelectedSat}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <ModalAddSatellite show={showAddSatModal} setShow={setShowAddSatModal} />
        </>
    );
};

export default Home;
